
import { IonRouterOutlet, isPlatform } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStorage } from "@/composables/useStorage";
import { SplashScreen } from "@capacitor/splash-screen";
import { isAfter } from "date-fns";

export default defineComponent({
  name: "App",
  components: {
    IonRouterOutlet
  },
  async setup() {
    const router = useRouter();
    const { getUser } = useStorage();
    const user = await getUser();
    let route = "";
    
    // User has logged in before
    if (user && user.token && user.expiresIn) {
      // User is on a mobile device. Prompt PIN entry
      if (isPlatform("hybrid")) {
        route = "Pin";

        // User is on a browser
      } else {
        // Is the token still valid?
        if (isAfter(new Date(user.expiresIn), new Date())) {
        
          
          //Forward to new expense view
          route = "New";
        } else {
          // Token is expired. Forward to login page
          route = "Login";
        }
      }
    } else {
      route = "Login";
    }

    const { getPreferences } = useStorage();
    const preferences = await getPreferences();
    document.body.classList.toggle("dark", preferences.darkMode);

    router.push({ name: route });

    // Give the app a second to render the view. Hides ugly transition
    setTimeout(() => {
      SplashScreen.hide();
    }, 1750);
    return {};
  }
});
