
import { IonApp, useBackButton } from "@ionic/vue";
import { defineComponent, Suspense } from "vue";
import { useNetwork } from "@/composables/useNetwork";
import { useFeature } from "@/composables/useFeature";
import Auth from "@/views/Auth.vue";
import { App } from "@capacitor/app";

export default defineComponent({
  name: "App",
  components: {
    Auth,
    IonApp
  },
  setup() {
    const { monitor, getStatus } = useNetwork();
    const { raiseToast } = useFeature();

    const initNetworkStatus = async () => {
      const status = await getStatus();
      if (!status.connected) {
        await raiseToast(
          "Network disconnected, rendering this app virtually useless :(",
          "danger"
        );
      }
    };

    useBackButton(50, () => {
      App.exitApp();
    });

    initNetworkStatus();
    monitor();
    return { Suspense };
  }
});
