import { loadingController, toastController } from '@ionic/vue';

export function useFeature() {
    const raiseToast = async (message: string, color: string, indefinite = false) => {
        const activeToast = await toastController.getTop();
        if (activeToast) {
            activeToast.dismiss()
        }
        (await toastController.create({
            message: message,
            duration: indefinite ? 0 : 2000,
            color: color,
            position: 'bottom'
        })).present();
    }

    const loading = async (message: string) => {
        const loading = await loadingController.create({
            message: message
        })
        return loading;
    }
    return { loading, raiseToast };
}