import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { useStorage } from '@/composables/useStorage'

const routes: Array<RouteRecordRaw> = [

  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/Login.vue'),
  },
  {
    name: 'Pin',
    path: '/pin',
    props: true,
    component: () => import('@/views/Pin.vue'),
  },
  {
    name: 'logout',
    path: '/logout',
    redirect: '/login'
  },
  {
    name: 'resetPin',
    path: '/resetPin',
    component: () => import('@/views/ResetPin.vue')
  },
  {
    path: '/tabs',
    component: () => import('@/views/Tabs.vue'),
    props: true,
    children: [
      {
        path: '',
        redirect: '/tabs/new'
      },
      {
        name: 'expenses',
        path: 'expenses',
        component: () => import('@/views/MyExpensesTab.vue')
      },
      {
        name: 'New',
        path: 'new',
        component: () => import('@/views/NewExpenseTab.vue'),
        props: true
      },
      {
        name: 'review',
        path: 'review',
        component: () => import('@/views/ReviewTab.vue')
      },
      {
        name: 'search',
        path: 'search',
        component: () => import('@/views/SearchTab.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const { checkTokenExpired } = useStorage();
  const expired = await checkTokenExpired();

  if (to.name === 'resetPin') {
    next();
  }
  else if (to.name !== 'Login' && expired) {
    next({ name: 'Login' })
  }
  else {
    next();
  }
})

export default router
