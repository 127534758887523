import { Preferences } from '@capacitor/preferences';

const userKey = "user";
const pinKey = "pin";
const preferencesKey = "preferences";
const defaultPreferences = {
    darkMode: false,
    openCameraOnLaunch: false
}

export function useStorage() {
    const setPreferences = async (preferences: any) => {
        await Preferences.set({ key: preferencesKey, value: JSON.stringify(preferences) });
    }
    const getPreferences = async () => {
        const preferences = await Preferences.get({ key: preferencesKey });
        if (!preferences.value) {
            await setPreferences(defaultPreferences);
            return defaultPreferences
        }
        else {
            return JSON.parse(preferences.value)
        }
    }
    const getUser = async () => {
        const u = await Preferences.get({ key: userKey })
        return u.value ? JSON.parse(u.value) : null
    }
    const setUser = async (userDetails: any) => {
        await Preferences.set({ key: userKey, value: JSON.stringify(userDetails) })
    }
    // const setAccessData = async (accessData: any) => {
    //     const user = await getUser();
    //     user.token = accessData.token;
    //     user.expiresIn = accessData.expiresIn;
    //     await setUser(user);
    // }
    const getPin = async () => {
        return await Preferences.get({ key: pinKey })
    }
    const setPin = async (pin: string) => {
        await Preferences.set({ key: pinKey, value: pin })
    }
    const resetPin = async () => {
        await Preferences.remove({ key: pinKey })
    }
    const pinIsSetup = async () => {
        const pin = await getPin();
        return pin.value !== null && pin.value.length > 0;
    }
    const checkTokenExpired = async () => {
        const user = (await getUser())
        if (user) {
            return user.expiresIn - Date.now() <= 0;
        }
        else {
            return true;
        }
    }
    return { checkTokenExpired, getPin, pinIsSetup, getPreferences, setPin, resetPin, setPreferences, getUser, setUser }
}
