import { Network } from "@capacitor/network";
import { ref } from "@vue/runtime-core";
import { useFeature } from "@/composables/useFeature";

const isInternet = ref<boolean>();

export function useNetwork() {
    const monitor = () => {
        const { raiseToast } = useFeature();
        Network.addListener('networkStatusChange', newStatus => {
            if (newStatus.connected !== isInternet.value) {
                isInternet.value = newStatus.connected;
                if (!newStatus.connected) {
                    raiseToast("Network disconnected, rendering this app virtually useless :(", "danger")
                }
                else {
                    raiseToast("Network reconnected.", "success")
                }
            }
        })
    }
    const getStatus = async () => {
        const status = await Network.getStatus();
        isInternet.value = status.connected;
        return status;
    }
    return { isInternet, monitor, getStatus }
}